import React from "react"
import { graphql } from "gatsby"
import Carousel, { consts } from 'react-elastic-carousel'
import { Columns, Column } from 'bloomer';
import Layout from "../components/Layout"
import Seo from "../components/seo";
import {
  ContDetails,
  Image,
  Details,
  Format,
  Dimensions,
  Year,
  ContIcoClose,
  MyAniLink,
  GlobalStyleObra, ContArrows, ItemCarouselImg, ItemCarousel, ContItemCarousel, Description,
} from './stylesObraPage';
import {GlobalBackgroundColors, GlobalStyle} from "../styles/GlobalStyles";
import icoClose from '../images/close.svg';

export const query = graphql`
  query ( $subject_min: String!, $url: String!, $id_seccion: Int! ) {
    allMysqlObras (
      filter: {
        subject_min: { eq: $subject_min },
        url: { eq: $url }
      }
    ) {
      nodes {
        id
        id_obra
        id_seccion
        position
        status
        title
        year
        description
        original_format
        physical_dimensions
        subject
        subject_min
        creator
        url
        file_name
        mime_type
        image
      }
    }
    allMysqlObraGroup (
      sort: {fields: position},
      filter: {
        id_seccion: { eq: $id_seccion }
      }
    ) {
      nodes {
        id
        id_obra
        id_obra_padre
        id_seccion
        position
        status
        title
        year
        description
        original_format
        physical_dimensions
        subject
        subject_min
        creator
        url
        url_interactivo
        file_name
        mime_type
        image
      }
    }
    site {
      siteMetadata {
        env
      }
    }
  }
`;

const ObraPage = ({data}) => {
  //console.log('props => ', props);
  //console.log('data => ', data);

  const { env } = data.site.siteMetadata;
  //console.log('env => ', env);

  let PATH_IMG = '../../images-obras';

  if(env === 'development'){
    PATH_IMG = 'http://localhost:8888/Monica-Castillo/monica-castillo-obras-all';
  }

  const obra = data.allMysqlObras.nodes[0];
  const obraC = data.allMysqlObraGroup;
  let ID = obra.id_obra;

  //console.log('obra => ', obra);
  //console.log('obraC => ', obraC);

  const tecnicaObras = obra.subject_min;
  //console.log('tecnicaObras => ', tecnicaObras);

  let imgCarousel;

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? '〈' : '〉';
    return (
      <ContArrows
        onClick={onClick} disabled={isEdge}
      >
        <div>
          {pointer}
        </div>
      </ContArrows>
    )
  }

  if (obra.position === 1) {
    imgCarousel = <Carousel
        isRTL={false}
        itemsToScroll={1}
        itemsToShow={1}
        itemPadding={[0, 0]}
        renderArrow={myArrow}
        outerSpacing={0}
      >
        {
          obraC.nodes.map(obra => {
            console.log('obra =>', obra);

            if(ID === obra.id_obra_padre){
              return (
                <ContItemCarousel>
                  <ItemCarousel
                    id={'ic-1'}
                    className={'ic-active'}
                    key={obra.id_obra}
                  >
                    <ItemCarouselImg bgImg={`${PATH_IMG}/${obra.file_name}`}/>
                  </ItemCarousel>
                  {obra.url_interactivo
                    ? (
                      <audio controls autoPlay={false}>
                        <source src={`${PATH_IMG}/${obra.url_interactivo}`} type="audio/mpeg"/>
                        Your browser does not support the audio element.
                      </audio>
                    ) : null
                  }
                </ContItemCarousel>
              )
            }else{
              return null
            }
          })
        }
      </Carousel>
  } else {
    imgCarousel = <div className={'padding-left-15'}><Image
      src={`${PATH_IMG}/${obra.file_name}`}
      alt={obra.title}
    /></div>
  }

  return (
    <Layout
      themeColor={'light'}
      clsOverflow={true}
    >
      <Seo
        title={`${obra.title} | ${tecnicaObras}`}
        description={`${obra.title}`}
        works=''
        bolImage='0'
        ogImage={`${obra.image}`}
        twitterImage={`${obra.image}`}
      />
      <GlobalStyle/>
      <GlobalStyleObra/>
      <Columns
        isMultiline
        className="columns-responsive margin-no full-height"
        key={obra.id_obra}
      >
        <Column
          className='padding-no cont-picture-obra'
          isSize={{mobile: 12, tablet: 12, desktop: 7, widescreen: 7}}
        >


          {imgCarousel}


          { /* {obra.filename} */ }
          { /* <img src={obra.image} alt={obra.id} /> */ }
          { /* <img src={obra.mysqlImage.childImageSharp.fluid.src} alt={obra.id} /> */ }
        </Column>
        <Column
          className='padding-no'
          isSize={{mobile: 12, tablet: 12, desktop: 5, widescreen: 5}}
        >
          <ContDetails>
            <Details>
              {obra.title}
            </Details>
            <Format>
              {obra.original_format}
            </Format>
            <Dimensions>
              {obra.physical_dimensions}
            </Dimensions>
            <Year>
              {obra.year}
            </Year>
            <Description>
              {obra.description}
            </Description>
          </ContDetails>

          <ContIcoClose>
            <MyAniLink
              paintDrip
              hex={GlobalBackgroundColors.bgContentLight}
              // to={`/obras/${tecnicaObras}#${obra.url}`}
              to={`/${tecnicaObras}#${obra.url}`}
              state={{ obra: `${obra.url}` }}
            >
              <img src={icoClose} alt={'Close'} />
            </MyAniLink>
          </ContIcoClose>

        </Column>
      </Columns>
    </Layout>
  )
}

export default ObraPage
