import styled, {createGlobalStyle} from 'styled-components';
import {Link} from "gatsby";
import {GlobalFonts} from "../styles/fonts";
import {GlobalColors} from "../styles/GlobalStyles";
import AniLink from "gatsby-plugin-transition-link/AniLink"

export const GlobalStyleObra = createGlobalStyle`
  .cont-picture-obra{
    @media(min-width: 300px) and (max-width: 767px){
      padding-top: 50px !important;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      padding-top: 0 !important;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      padding-top: 0 !important;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      padding-top: 0 !important;
    }
    @media(min-width: 1408px){
      padding-top: 0 !important;
    }
  }
  .rec-dot_active{
    background-color: rgb(160 150 179 / 50%) !important;
    box-shadow: 0 0 1px 3px rgb(122 119 128);
  }
  .padding-left-15{
    padding-left: 15px;
  }
`
export const ContArrows = styled.div`
  color: ${GlobalColors.colorBorderDark};
  font-size: 60px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  cursor: pointer;
`
export const ContItemCarousel = styled.div`
  position: relative;
  width: 100%;
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media(min-width: 300px) and (max-width: 767px){
    height: 380px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    height: 500px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    height: 600px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    height: 700px;
  }
  @media(min-width: 1408px){
    height: 700px;
  }
`
export const ItemCarousel = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  
  /*@media(min-width: 300px) and (max-width: 767px){
    height: 380px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    height: 500px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    height: 600px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    height: 700px;
  }
  @media(min-width: 1408px){
    height: 700px;
  }*/
`
export const ItemCarouselImg = styled.div`
  width: 100%;
  height: 100%;
  
  background-image: url(${props => props.bgImg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  
`
export const Image = styled.img`
  position: relative;
  width: 100%;
  height: auto;
`
export const ContDetails = styled.div`
  position: relative;
  margin: 0 auto;
  background-color: rgba(255,255,255,.9);
  
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  
  @media(min-width: 300px) and (max-width: 767px){
    padding-bottom: 30px;
    width: 85%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 85%;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    margin: 180px auto;
    width: 85%;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    margin: 180px auto;
    width: 85%;
  }
  @media(min-width: 1408px){
    margin: 180px auto;
    width: 85%;
  }
`
export const Details = styled.div`
  font-family: ${GlobalFonts.fontMedium};
  color: ${GlobalColors.colorPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 25px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 22px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 326px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 30px;
  }
  @media(min-width: 1408px){
    font-size: 30px;
  }
`
export const Format = styled.div`
  font-family: ${GlobalFonts.fontRegular};
  color: ${GlobalColors.colorPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 12px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 16px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 20px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 20px;
  }
  @media(min-width: 1408px){
    font-size: 20px;
  }
`
export const Dimensions = styled.div`
  font-family: ${GlobalFonts.fontLight};
  color: ${GlobalColors.colorPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 12px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 14px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 18px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 18px;
  }
  @media(min-width: 1408px){
    font-size: 18px;
  }
`
export const Year = styled.div`
  margin-top: 5px;
  font-family: ${GlobalFonts.fontLight};
  color: ${GlobalColors.colorPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 8px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 12px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 14px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 14px;
  }
  @media(min-width: 1408px){
    font-size: 14px;
  }
`

export const Description = styled.div`
  margin-top: 10px;
  font-family: ${GlobalFonts.fontLight};
  color: ${GlobalColors.colorPrimary};

  @media(min-width: 300px) and (max-width: 767px){
    font-size: 12px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 14px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 18px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 18px;
  }
  @media(min-width: 1408px){
    font-size: 18px;
  }
`

export const ContTrazo = styled.div`
  position: relative;
  float: left;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 137px;
    height: 20px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 220px;
    height: 24px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 220px;
    height: 24px;
  }
  @media(min-width: 1216px) and (max-width: 1365px){
    width: 220px;
    height: 24px;
  }
  @media(min-width: 1366px) and (max-width: 1407px){
    width: 254px;
    height: 30px;
  }
  @media(min-width: 1408px){
    width: 254px;
    height: 30px;
  }
`

export const ContIcoClose = styled.div`
  position: absolute;
  top: 0;

  width: 45px;
  height: 45px;
  
  @media(min-width: 300px) and (max-width: 767px){
    right: 15px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    right: 25px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    right: 56.5px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    right: 56.5px;
  }
  @media(min-width: 1408px){
    right: 56.5px;
  }
`

export const LinkGatsby = styled(Link)`
  
  width: 100%;
  
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
export const MyAniLink = styled(AniLink)`
  
  width: 100%;
  
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
